<template>
  <td>{{ student.lastname }}</td>
  <td>{{ student.firstname }}</td>
  <td>{{ student.category }}</td>
  <td>{{ student.dispensedHours }}</td>
  <td><label class="checkbox"><input :checked="student.graduated" disabled type="checkbox"></label></td>
  <td><label class="checkbox"><input :checked="student.enable" disabled type="checkbox"></label></td>
  <td>
    <button class="button is-ghost is-rounded" @click="editLine">
      <ion-icon class="brush" name="brush"></ion-icon>
    </button>
  </td>
  <td>
    <button class="button is-ghost is-rounded" @click="deleteLine">
      <ion-icon class="trash" name="trash"></ion-icon>
    </button>
  </td>
</template>

<script>
import store from "../../store";

export default {
  name: "StudentLine",
  props: ['student'],
  emits: ["editStudent"],
  data() {
    return {
      editing: null,
    }
  },
  methods: {
    editLine() {
      this.$emit('editStudent', this.student.id)
    },
    deleteLine() {
      if (confirm(`Supprimer l'élève ${this.student.lastname} ${this.student.firstname} ?`)) {
        store.dispatch('deleteStudent', this.student.id)
      }
    }
  }
}
</script>

<style scoped>

td {
  text-align: center;
  vertical-align: middle;
}

.brush {
  color: #5019DBD1;
  cursor: pointer;
}

.brush:hover {
  color: #906FE3D1;
}

.trash {
  color: #f64949;
  cursor: pointer;
}

.trash:hover {
  color: #ffa1a1;
}

</style>