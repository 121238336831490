<template>
  <div class="students-view">
    <student-form></student-form>
    <student-list :students="getStudents"></student-list>
  </div>
</template>

<script>
import StudentForm from "../components/students/StudentForm";
import StudentList from "../components/students/StudentList";
import {mapGetters} from "vuex";

export default {
  name: "Students",
  components: {StudentList, StudentForm},
  computed: {
    ...mapGetters([
        'getStudents',
    ])
  },
  methods: {
  }
}
</script>

<style scoped>
.students-view {
  width: 100%;
}
</style>