<template>
  <div class="students-list">
    <table class="table students-table">
      <thead>
      <tr>
        <th>Nom</th>
        <th>Prénom</th>
        <th>Catégorie</th>
        <th>Heures</th>
        <th>Diplômé</th>
        <th>Actif</th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="student in this.students" :key="student.id">
        <student-line :student="student" v-if="student.id !== editingLineId" @editStudent="editStudent"></student-line>
        <student-editing-line :student="student" v-if="student.id === editingLineId" @cancelEditing="cancelEditing"></student-editing-line>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import StudentLine from "./StudentLine";
import StudentEditingLine from "./StudentEditingLine";
export default {
  name: "StudentList",
  components: {StudentLine, StudentEditingLine},
  props: ['students'],
  data() {
    return {
      editingLineId: null,
    }
  },
  methods: {
    editStudent(studentId) {
      this.editingLineId = studentId;
    },
    cancelEditing() {
      this.editingLineId = null;
    }
  }
}
</script>

<style scoped>
.students-list {
  overflow-y: auto;
}

tbody tr:nth-child(odd){
  background-color: #f2e3ff;
}

tbody tr:nth-child(even){
  background-color: #f9f4ff;
}

td {
  text-align: center;
  vertical-align: middle;
}

.students-table {
  width: 100%;
}

</style>