<template>
  <form @submit="addUser" name="student-form">
    <div class="card student-form">
      <div class="card-content">
        <div class="content">
          <input type="text" v-model="lastname" @change="formatLastname" @focus="$event.target.select()" class="input" placeholder="Nom"/>
          <input type="text" v-model="firstname" @change="formatFirstName" @focus="$event.target.select()" class="input" placeholder="Prénom"/>
          <div class="category">
            Catégorie :
            <div class="select">
              <select v-model="category">
                <option value="B">B</option>
                <option value="AAC">AAC</option>
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="A">A</option>
                <option value="AM">AM</option>
                <option value="BE">BE</option>
                <option value="B96">B96</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <footer class="card-footer">
        <button type="submit" class="button is-primary card-footer-item"
                :disabled="!this.firstname || !this.lastname || !this.category">AJOUTER
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import store from "../../store";

export default {
  name: "StudentForm",
  data() {
    return {
      lastname: null,
      firstname: null,
      category: "B",
      db: null,
      user: null,
    }
  },
  mounted() {
    this.db = getFirestore();
  },
  methods: {
    addUser() {
      store.dispatch('addStudent', {
        firstname: this.capitalizeFirstLetter(this.firstname),
        lastname: this.lastname.toUpperCase(),
        category: this.category,
        graduated: false,
        dispensedHours: 0,
        graduationHours: 0,
        enable: true,
        user_uid: getAuth().currentUser.uid
      }).then(() => {
        this.firstname = null;
        this.lastname = null;
      })
    },
    formatFirstName() {
      return this.firstname = this.firstname.charAt(0).toUpperCase() + this.firstname.slice(1);
    },
    formatLastname() {
      this.lastname = this.lastname.toUpperCase();
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  }
}
</script>

<style scoped>
.student-form {
}

.category {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

select {
  min-width: 100px;
  width: auto;
  text-align: center;
  margin-left: 5px;
}

input {
  text-align: center;
  min-width: 300px;
  width: auto;
}
</style>