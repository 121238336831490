<template>
  <td><input type="text" class="input" @focus="$event.target.select()" @change="formatLastname" v-model="lastname"></td>
  <td><input type="text" class="input" @focus="$event.target.select()" @change="formatFirstName" v-model="firstname"></td>
  <td>
    <div class="category">
      <div class="select">
        <select v-model="category">
          <option value="B">B</option>
          <option value="AAC">AAC</option>
          <option value="A1">A1</option>
          <option value="A2">A2</option>
          <option value="A">A</option>
          <option value="AM">AM</option>
          <option value="BE">BE</option>
          <option value="B96">B96</option>
        </select>
      </div>
    </div>
  </td>
  <td><input type="number" step="0.5" min="0" class="input" @focus="$event.target.select()" v-model="dispensedHours"></td>
  <td><label class="checkbox"><input :checked="student.graduated" @focus="$event.target.select()" type="checkbox" v-model="graduated"></label></td>
  <td><label class="checkbox"><input :checked="student.enable" @focus="$event.target.select()" type="checkbox" v-model="enable"></label></td>
  <td>
    <button class="button is-ghost is-rounded" @click="patchStudent">
      <ion-icon class="checkmark" name="checkmark-circle"></ion-icon>
    </button>
  </td>
  <td>
    <button class="button is-ghost is-rounded" v-on:click="cancelEditing">
      <ion-icon class="close" name="close-circle"></ion-icon>
    </button>
  </td>
</template>

<script>
import store from "../../store";

export default {
  name: "StudentLine",
  props: ['student'],
  emits: ['cancelEditing'],
  data() {
    return {
      editing: null,
      lastname: this.student.lastname.toUpperCase(),
      firstname: this.student.firstname,
      category: this.student.category,
      dispensedHours: this.student.dispensedHours ? this.student.dispensedHours : 0,
      graduated: this.student.graduated,
      enable: this.student.enable,
    }
  },
  methods: {
    patchStudent() {
      store.dispatch('updateStudent', {
        id: this.student.id,
        toPatch: {
          firstname: this.capitalizeFirstLetter(this.firstname),
          lastname: this.lastname.toUpperCase(),
          category: this.category,
          graduated: this.graduated,
          dispensedHours: this.dispensedHours ? this.dispensedHours : 0,
          enable: this.enable,
        }
      })
      this.cancelEditing();
    },
    cancelEditing() {
      this.$emit('cancelEditing')
    },
    formatFirstName() {
      return this.firstname = this.firstname.charAt(0).toUpperCase() + this.firstname.slice(1);
    },
    formatLastname() {
      this.lastname = this.lastname.toUpperCase();
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  }
}
</script>

<style scoped>

tbody tr:nth-child(odd) {
  background-color: #6800ff;
}

tbody tr:nth-child(odd) {
  background-color: #6800ff;
}

td {
  text-align: center;
  vertical-align: middle;
}

input {
  text-align: center;
  width: auto;
}

.checkmark {
  color: #5019DBD1;
  cursor: pointer;
}

.checkmark:hover {
  color: #906FE3D1;
}

.close {
  color: #f64949;
  cursor: pointer;
}

.close:hover {
  color: #ffa1a1;
}

</style>